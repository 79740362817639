.header {
  background-color: transparent;
  background-image: linear-gradient(180deg, $black 50%, #00f2ff00 100%);
  color: $white;
  font-size: 16px;
  font-weight: 100;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 5px 0;
  z-index: 1000;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 0 8px;
    z-index: 1000;
  }

  &__logo {
    z-index: 1000;
    img {
      height: 90px;
    }
  }

  &__nav {
    flex-direction: column;
    position: fixed;
    top: 50px;
    right: -100%;
    transition: 0.5s;
    width: 30%;
    height: 100%;
    padding-top: 100px;
    background: rgba(0, 10, 13, 0.8);
    backdrop-filter: blur(10px);
    border-left: 1px solid rgba(255, 255, 255, 0.1);

    .header__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 1.5rem;
      position: relative;
      margin-left: 2rem;

      a {
        color: $white;
        text-decoration: none;
      }

      .header__list-item {
        padding: 10px 0;
        text-decoration: none;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          width: 0;
          height: 1px;
          background: linear-gradient(
            90deg,
            #ae8625,
            #f7ef8a,
            #d2ac47,
            #edc967
          );
          transition: 0.3s;
          will-change: width;
        }

        &:hover {
          color: $accent;

          &::after {
            width: 100%;
            left: 0;
            background: linear-gradient(
              90deg,
              #ae8625,
              #f7ef8a,
              #d2ac47,
              #edc967
            );
          }
        }
      }
    }

    .header__list-item {
      min-height: 1rem;
    }

    &.active {
      right: 0;
    }
  }

  .dropdown-inner {
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: left;

    > i {
      padding-top: 2px;
    }
  }

  .dropdown {
    cursor: pointer;
    margin-bottom: 5rem;
    span {
      margin-top: 0.25rem;
    }
  }

  &__sub-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s linear;
  }

  .sub-list_trigger {
  }

  .sub-list_trigger.active {
    color: $accent;

    .header__list-item {
      height: 100%;
    }
    .header__sub-list {
      max-height: 100px;
    }
    &::after {
      width: 100%;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(191, 158, 119, 1),
        rgba(191, 158, 119, 1)
      );
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    gap: 2rem;
    z-index: 100;
  }

  &__tel {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: 'Futura PT';
    background: $gold-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-mask-image: linear-gradient(
      -75deg,
      rgba(0, 0, 0, 0.9) 10%,
      #000 30%,
      rgba(0, 0, 0, 0.7) 70%
    );
    -webkit-mask-size: 200%;
    animation: wave 3s infinite;
    -moz-animation: wave 3s infinite;
    text-decoration: none;

    @media (max-width: 767px) {
      font-size: 1.25rem;
    }
  }

  &__tel-label {
    display: inline-block;
    position: relative;
    font-size: 0.75em;

    &::after {
      content: '';
      position: absolute;
      width: 0.667em;
      height: 0.667em;
      left: -1em;
      top: 50%;
      margin-top: -0.333em;
      background-color: #aaff3f;
      border-radius: 100%;
      box-shadow: 0 0 0 0 #000;
      transform: scale(1);
      animation: pulse 2s infinite;
    }
  }

  @keyframes wave {
    from {
      -webkit-mask-position: 100%;
    }
    to {
      -webkit-mask-position: -100%;
    }
  }

  &__menu {
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      margin-top: -1rem;

      @media (max-width: 767px) {
        margin-top: 0;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 100;
    }
  }

  &__favourite {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: 0.3s all;
    text-decoration: none;
    color: white;

    &:hover {
      color: $accent;
    }
  }

  .hamburger {
    display: block;
    cursor: pointer;

    .bar {
      display: block;
      width: 3rem;
      height: 3px;
      margin: 6px auto;
      transition: all 0.3s ease-in-out;
      background-color: $white;
    }

    &.active {
      .bar:nth-child(1) {
        transform: translateY(4px) rotate(45deg);
      }
      .bar:nth-child(2) {
        transform: translateY(-4px) rotate(-45deg);
      }
    }
  }

  &__overlay {
    video {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 928px) {
    .dropdown-inner {
      text-align: center;
    }
    &__nav {
      width: 100%;
      border: none;

      .header__list {
        align-items: center;
        margin: 0;
      }
    }

    &__list-item {
      text-align: center;
    }

    &__sub-list {
      align-items: center;
    }
  }

  @media screen and (max-width: 768px) {
    &__logo {
      padding-top: 0.5rem;
      img {
        height: 3.25rem;
      }
    }

    &__row {
      flex: 1;
    }

    &__tel {
      //padding-right: 90px;
      font-size: 0.75rem;
    }

    .hamburger {
      margin-top: 0.5rem;
      display: block;

      .bar {
        display: block;
        width: 2.5rem;
        height: 2px;
      }
    }

    &__favourite {
      //padding-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      transition: 0.3s all;
      text-decoration: none;
      color: white;

      span {
        font-size: 1rem;
      }

      .bookmark {
        fill: white;
      }
    }

    &__menu {
      gap: 1.5rem;
    }
  }
}

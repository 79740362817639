.footer {
  //flex: 0 0 auto;
  padding: 64px 0;

  &__row {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 140px;
    text-align: center;
    text-decoration: none;
    color: $white;

    img {
      width: 100%;
    }

    span {
      font-size: 8px;
      line-height: 1;
      opacity: 0.8;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__menu-title {
    position: relative;
    margin-bottom: 16px;
    font-size: 16px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -0.5em;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0.2),
        hsla(0, 0%, 100%, 0)
      );
    }
  }

  &__menu-list {
    grid-column-gap: 5rem;
    grid-row-gap: 1rem;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, 1fr);
    width: max-content;

    @media (max-width: 767px) {
      grid-template-rows: repeat(4, 1fr);
    }

    a {
      position: relative;
      font-size: 12px;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.8);
      transition: 0.3s;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: -0.5em;
        width: 0;
        height: 1px;
        background: linear-gradient(
          90deg,
          rgba(191, 158, 119, 0),
          rgba(191, 158, 119, 1)
        );
        transition: 0.3s;
        will-change: width;
      }

      &:hover {
        color: $accent;

        &::after {
          width: 100%;
          left: 0;
          background: linear-gradient(
            90deg,
            rgba(191, 158, 119, 1),
            rgba(191, 158, 119, 1)
          );
        }
      }
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__phone {
    display: block;
    text-align: center;
    font-size: 24px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 12px;
    text-decoration: none;
    color: $accent;

    text-transform: uppercase;
    background: $gold-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-mask-image: linear-gradient(
      -75deg,
      rgba(0, 0, 0, 0.9) 10%,
      #000 30%,
      rgba(0, 0, 0, 0.7) 70%
    );
    -webkit-mask-size: 200%;
    animation: wave 3s infinite;
  }

  &__soc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    a {
      display: inline-block;
      background-size: contain;
      background-position: 50%;
      background-right: no-repeat;

      img {
        width: 1.5rem;
        height: 1.5rem;
        background-color: white;
      }
    }
  }

  &__info {
    margin-top: 12px;
  }

  &__privacy-row {
    display: flex;
    gap: 8px;
    margin-top: 1.25em;

    a {
      display: inline-block;
      max-width: 100px;
      font-size: 8px;
      font-weight: 400;
      line-height: 1;
      color: $white;
      opacity: 0.8;
      min-width: 32px;
    }
  }

  @media screen and (max-width: 768px) {
    &__row {
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }

    &__logo {
      margin-bottom: 12px;
    }

    &__menu {
      padding: 0 24px;
    }

    &__contacts {
      margin-top: 24px;
    }
  }
}

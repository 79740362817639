.first-screen {
  &__logo {
    max-width: 20em;
    width: 90%;
  }
}
.business-center_description {
  background-color: #f5f6fa;
  &__row {
    padding: 5rem 1rem;
    color: black;
  }

  &__title {
    text-align: center;
    font-size: 3rem;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  &__description {
    margin-top: 3rem;
    font-size: 1.25rem;
    font-family: 'Futura PT';
    font-weight: 300;

    strong {
      font-weight: 700;
    }

    ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 0.5rem;
      margin-block-end: 0.5rem;
      padding-inline-start: 1rem;
    }

    li {
      display: list-item;
    }

    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
}
.business {
  background-color: white;

  &__row {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-top: 5rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    list-style: none;
    gap: 1rem;
    margin-top: 1rem;
  }

  &__item {
    width: 100%;
    height: 30rem;
    position: relative;
    color: white;

    border-radius: 0.5rem;

    &:hover {
      .business__overlay {
        opacity: 1;
      }
    }
  }

  &__image {
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }

  &__image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.5rem;

    background-color: rgba(0, 0, 0, 0.5);
  }

  &__overlay {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 1;

    border-radius: 0.5rem;

    transition: opacity 0.2s linear;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__overlay_detail {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__overlay_detail-item {
    font-size: 1.25rem;
    line-height: 1.2;
  }

  &__title {
    border: none;
    background: none;
    font-size: 2em;
    top: 1rem;

    text-align: center;
    font-family: Wremena;
  }

  &__logo {
    width: 60%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .filter_area {
    position: relative;
    width: 100%;
    height: 3rem;
    background-color: $black;
  }

  .menu_list {
    display: flex;
    justify-content: flex-end;
    color: white;
    position: relative;
    width: 100%;
    height: 3rem;
    background-color: $black;

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      span {
        font-size: 3rem;
      }

      .open-menu {
        cursor: pointer;
      }
    }
  }
  .filters {
    position: absolute;
    top: 3rem;
    right: 0;

    max-height: 0;
    overflow: hidden;
    opacity: 0;

    z-index: 100;

    &.active {
      opacity: 1;
      max-height: 1500px;
      overflow: scroll;
    }
  }

  &-products {
    &__title {
      color: black;
      font-size: 3rem;
      font-family: Wremena;

      @media (max-width: 767px) {
        font-size: 2rem;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1315px) {
    &__list {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 956px) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 477px) {
    //&__list {
    //  display: flex;
    //  flex-direction: column;
    //}

    &__row {
      padding: 1rem;
    }

    &__item {
      height: 15rem;
      width: 100%;
    }

    &__title {
      font-size: 1rem;
    }

    &__image-overlay {
      display: none;
    }

    &__overlay {
      opacity: 1;
    }

    &__overlay_detail-item {
      font-size: 0.75rem;
    }
  }
}
.business_products {
  .products__row {
    justify-content: center;
  }
}
.business-images {
  overflow-x: hidden;
  position: relative;
  padding: 5rem 0;
  background-color: #f5f6fa;

  &__carousel {
  }

  &__item {
    width: 45rem;
    height: 27.25rem;
    line-height: 0;
    will-change: transform, opacity;
    transform: scale(0.234) translateX(392%);
    opacity: 0.2;
    transition: 0.3s;

    &:not(:first-child) {
      margin-left: 3.75rem;
    }

    &.siblings {
      &-next {
        opacity: 0.5;
        transform: scale(0.47) translateX(-57%);
      }

      &-prev {
        opacity: 0.5;
        transform: scale(0.47) translateX(57%);
      }
    }

    &.is-selected {
      opacity: 1;
      transform: scale(1) translateX(0);

      & ~ .business-images__item:not(.siblings-next) {
        transform: scale(0.5) translateX(-392%);
      }
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;

      @media (max-width: 767px) {
        border-radius: 4px;
      }
    }

    @media (max-width: 767px) {
      width: 21.25em;
      height: 13.25em;
      transform: scale(0.8) translateX(13%);
      opacity: 1;

      &:not(:first-child) {
        margin-left: 1.25em;
      }

      &.siblings {
        &-next {
          opacity: 1;
          transform: scale(0.8) translateX(-13%);
        }

        &-prev {
          opacity: 1;
          transform: scale(0.8) translateX(13%);
        }
      }

      &.is-selected {
        opacity: 1;
        transform: scale(1) translateX(0);

        & ~ .office__item:not(.siblings-next) {
          transform: scale(0.8) translateX(-13%);
        }
      }
    }
  }
}
.business-advantages {
  position: relative;
  padding: 5rem 0;
  background-color: #f5f6fa;

  &__row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-family: Wremena;
    font-size: 3rem;
    color: black;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 7rem;
    row-gap: 5rem;
    margin-top: 3rem;

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 7rem;
      row-gap: 3rem;
      margin-top: 5rem;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;

    &-logo {
      max-width: 70px;
      width: 100%;
      height: auto;
    }

    &-title {
      margin-top: 0.5rem;
      font-size: 1.25rem;
      font-weight: 300;
      text-align: center;
      color: black;
      line-height: 1.75;

      @media (max-width: 767px) {
        font-size: 1.25rem;
      }
    }
  }
}

.business-layouts {
  background-color: $white;

  &__row {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &__title {
    font-family: Wremena;
    font-size: 3rem;
    color: black;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5rem;

    @media (max-width: 767px) {
      gap: 2rem;
    }
  }

  &__list-item {
    margin-top: 3rem;
  }

  &__layouts {
  }

  &__layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    &:not(:first-child) {
      margin-left: 10em;
    }
  }

  &__area {
    color: $black;
    font-size: 1.5rem;
  }

  &__room-count {
    font-family: Wremena;
    font-size: 3rem;
    color: black;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
    margin-bottom: 5rem;
  }
}

html,
body {
  font-family: 'Futura PT Book', sans-serif;
  color: $white;
  font-size: 16px;
  box-sizing: border-box;
  font-weight: 100;
  background-color: $black;
  height: 100%;

  &.no-scroll {
    overflow: hidden;
  }

  overflow-x: hidden;
}
.container {
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;
}
.container-middle {
  max-width: $container-middle-max-width;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.main {
  flex: 1 0 auto;
}

.btn-save {
  display: flex;
  align-items: center;
  background: none;

  &:hover {
    background: none;
    color: $accent;
  }
}

.title {
  margin: 0.5em 0;

  font-size: 3em;
  font-weight: 500;
  font-family: 'Wremena', sans-serif;
  text-transform: uppercase;
}
.title-modal {
  font-size: 2em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#map {
  height: 400px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}

.first-screen {
  &__row {
    height: 100vh;
    max-width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 16px;
    position: relative;

    background-size: cover;
    word-wrap: break-word;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: transparent;
    background-image: linear-gradient(180deg, #000000 0%, #000000a6 100%);
    opacity: 0.9;
    transition:
      background 0.3s,
      border-radius 0.3s,
      opacity 0.3s;
    object-fit: cover;

    z-index: 2;
  }

  &__bg-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    background-image: linear-gradient(180deg, #000000 0%, #000000a6 100%);
    opacity: 0.9;
    transition:
      background 0.3s,
      border-radius 0.3s,
      opacity 0.3s;

    z-index: 3;
  }

  &__video {
    width: 100%;
    height: 100vh;

    position: absolute;
    top: 0;
    left: 0;

    z-index: 2;

    overflow: hidden;
    object-fit: cover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  &__img {
    width: 250px;
    height: auto;
    margin-bottom: 16px;
  }

  &__title {
    text-align: center;
    font-family: 'Wremena';
  }

  &__sub-title {
    max-width: 700px;
    margin-bottom: 24px;

    position: absolute;
    right: 1em;
    bottom: 1em;
    z-index: 100;
    font-family: Wremena;
    font-weight: 300;

    font-size: 1.25em;
    line-height: 1.3;
    text-align: right;
  }

  &__paragraph {
    font-size: 36px;
    text-align: center;
    line-height: 1.3;
    margin-bottom: 24px;
  }

  &__gif {
    width: 80px;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    &__img {
      width: 250px;
      height: auto;
    }

    &__title {
      font-size: 2rem;
      margin-bottom: 24px;
    }

    &__sub-title {
      font-size: 1.25rem;
      bottom: -1rem;
      right: 0.5rem;
      padding: 0.5rem;
    }

    &__paragraph {
      font-size: 28px;
      text-align: center;
      line-height: 1.3;
      margin-bottom: 24px;
    }
  }
}

.tile-types {
  padding: 0 8px;
  font-family: Wremena;

  &__row {
    display: flex;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: flex-end;
    height: 400px;
    width: calc($container-max-width / 5);
    padding: 2.5em 1.875em;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    filter: grayscale(1);
    transition: filter 0.3s;
    color: $white;
    cursor: pointer;
    will-change: filter, transform;
    text-decoration: none;
    scroll-snap-align: start;

    &:nth-child(1) {
      background-image: url('../images/banners/homes.jpeg');
    }
    &:nth-child(2) {
      background-image: url('../images/banners/appartments_panel.webp');
    }
    &:nth-child(3) {
      background-image: url('../images/banners/commercial.jpg');
    }
    &:nth-child(4) {
      background-image: url('../images/banners/business_panel.webp');
    }
    &:nth-child(5) {
      background-image: url('../images/banners/land.jpg');
    }
    &:nth-child(6) {
      background-image: url('../images/banners/new_buildings.jpeg');
    }
  }

  &__item::after {
    content: '';
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 10, 13, 0) 30.87%,
      #000a0d 105.38%
    );
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__title {
    line-height: 1.3;
    letter-spacing: 0.1em;
    font-size: 16px;
    width: min-content;
    position: relative;
    z-index: 1;
    transition: color 0.3s;
  }

  &__item:hover {
    filter: grayscale(0);

    &__title {
      position: relative;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 8px 8px;

    &__row {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.25em;
    }

    &__item {
      width: auto;
      height: 250px;
      padding: 0.75em;
      border-radius: 0.25em;
    }
  }
}

.best-proposals {
  margin-top: 5em;

  &__row {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
  }

  &__list {
    margin-top: 3em;
  }

  &__item {
    width: 20em;
    height: 30em;
    border-radius: 0.2em;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    overflow: hidden;
  }

  &__item-image {
    width: 100%;
    height: 100%;
    border-radius: 0.3em;
    transition: all 0.3s ease-in;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__item-banner {
    position: absolute;
    top: 1em;
    //left: 1rem;
    padding: 1rem 2rem;
    font-size: 1.25em;
    background: linear-gradient(
      to left,
      rgba(251, 245, 183),
      rgba(191, 149, 63),
      rgb(179, 135, 40)
    );
  }

  img {
    object-fit: cover;
  }

  &__item-info {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1em 2em;
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
    background-color: rgba(0, 0, 0, 0.7);
  }

  &__item-title {
    margin-bottom: 0.5em;
  }

  @media screen and (max-width: 768px) {
    &__title {
      font-size: 2rem;
      text-align: center;
    }

    &__list {
      margin-top: 1rem;
    }

    &__item {
      margin: 0.5em;
      width: 15em;
      height: 25em;
      border-radius: 0.2em;
    }

    &__item-info {
      padding: 1rem;
    }
  }
}

.partners {
  margin-top: 120px;
  margin-bottom: 10em;

  &__row {
    display: flex;
    justify-content: space-between;
    padding: 0 1em;
  }

  &__subtitle {
    display: block;
    max-width: 500px;
    width: 100%;
    font-size: 2em;
    text-align: right;
  }

  &__carousel {
    margin-top: 36px;
  }

  &__carousel-cell {
    height: 240px;
    width: 240px;
    padding: 8px;
  }

  &__carousel-img {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px;

    img {
      height: auto;
      width: 100%;
      border-radius: 4px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media screen and (max-width: 1200px) {
    &__carousel-cell {
      height: 180px;
      width: 180px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 5rem;
    &__row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__carousel {
      margin-top: 1rem;
    }

    &__title {
      text-align: center;
      max-width: 100%;
      font-size: 2rem;
    }
  }
}

.catalog {
  //background-color: ##000a0c;
  padding: 2rem 0;
  &__row {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
  }

  &__title {
    z-index: 2;

    @media (max-width: 767px) {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  &-download {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1em;
    overflow: hidden;

    &__magazine {
      max-width: 850px;
      width: 100%;
      margin-left: -7rem;

      > video {
        width: 100%;
        transform: rotate(10deg);
      }

      @media (max-width: 767px) {
        margin-left: 0;
        width: 500px;
        overflow: hidden;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      z-index: 2;

      > button {
        @media (max-width: 767px) {
          font-size: 0.75rem;
          padding: 0.25rem 0.5rem;
        }
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__subtitle {
    max-width: 300px;
    padding: 0.25rem 0 0.25rem 1rem;

    font-size: 1.25em;
  }

  @media screen and (max-width: 768px) {
    &__subtitle {
      font-size: 1rem;
    }
  }
}

.office {
  margin-top: 120px;

  &__row {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
  }

  &__phone {
    display: block;
    margin-top: 1em;
    margin-bottom: 0.5em;
    text-align: center;
    font-size: 2em;
    font-weight: 500;
    text-decoration: none;
    color: $white;
  }

  &__address {
    margin-bottom: 0.5em;
    font-size: 1.5em;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    &__title {
      text-align: center;
      font-size: 2rem;
    }
    &__phone {
      text-align: center;
      font-size: 1.5rem;
    }
    &__address {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}

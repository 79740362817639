.products {
  background-color: white;
  padding: 1rem;

  &__row {
    display: flex;
    gap: 2rem;
  }

  .btn {
    padding: 0.75em 1em;
  }

  &__map.btn {
    padding: 0.75rem 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 3em;
    flex-basis: 70%;
  }

  &__item {
    display: flex;
    color: #000;

    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.14);
    background-color: $white;
  }

  &__img {
    max-width: 350px;
    height: 350px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;

      overflow: hidden;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    padding-bottom: 1em;
  }

  &__articles {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 2rem;

    .btn {
      padding: 0.75rem 0;
    }
  }

  &__favourite {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: 0.3s;
    text-decoration: none;
    color: inherit;
    background: none;

    &:hover {
      color: $accent;
      background: none;
    }

    &.active {
      color: $accent;
      background: none;
    }
  }

  &__title {
    padding: 0 2rem;
    font-size: 2rem;
    color: black;
    text-decoration: none;
    font-family: Wremena;
    margin-bottom: 1rem;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    gap: 1rem;

    background-color: #f5f4f4;

    padding: 1rem 2rem;

    //font-family: Wremena;
    font-size: 1.25em;
    margin-bottom: 1em;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
  }

  &__make-order {
  }

  &__map,
  &__more-detail {
    background: none;
    color: black;

    &:hover {
      background: none;
      color: $accent-hover;
    }
  }

  &__not-found {
    max-width: 900px;
    width: 100%;

    &_row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      line-height: 1.5;
      text-align: center;
    }
    &_title {
      color: $black;
      font-size: 1.75rem;
      font-family: 'Futura PT';
      font-weight: 400;
      text-transform: uppercase;

      @media (max-width: 767px) {
        font-size: 1.5rem;
      }
    }
    &_form {
      margin-top: 2rem;
      max-width: 300px;
      width: 100%;

      &-row {
        height: 3rem;

        input {
          width: 100%;
          height: 100%;

          padding: 0 1.25rem;
          overflow: visible;
          outline: 0;
          margin: 0;

          background-color: transparent;
          border: 1px solid rgba(255, 255, 255, 0.2);
          font-family: Futura PT;
          color: black;
          font-size: 1rem;
        }
      }
    }

    &_contacts {
      &-title {
        color: black;
        //font-size: .75rem;
        color: #181818;
      }

      &-items {
        display: flex;
        justify-content: center;
        gap: 5rem;
        margin-top: 1rem;

        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  @media screen and (max-width: 953px) {
    &__row {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    &__item {
      flex-direction: column;
    }

    &__img {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 457px) {
    &__info {
      padding: 0;
    }

    &__img {
      height: 250px;
    }
    &__articles {
      padding: 0 1rem;
    }

    &__title {
      padding: 0 1rem;
      font-size: 1.5rem;
    }

    &__details {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }

    &__actions {
      flex-direction: column;
      padding: 0 1rem;
    }

    &__more-detail {
      display: none;
    }

    &__map {
      margin: 0.3rem 0;
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }

  > ul {
    list-style-type: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    > li {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      border-radius: 2px;
      border: 2px solid transparent;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.2s ease-in;
      color: #9199ad;

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
        padding-top: 2px;
      }

      &:hover {
        background-color: #f5f4f4;
        color: $black;
      }

      &.active {
        background-color: #f5f4f4;
        color: $black;
        border: 2px solid #e7e7e7;
      }

      &.prev {
        background-color: $accent;
        padding-right: 2px;
      }
      &.next {
        background-color: $accent;
        padding-left: 2px;
      }
    }
  }
}

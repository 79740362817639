.modals {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.popup {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.5);
  color: $white;

  visibility: hidden;

  &__body {
    width: auto;
    height: 30rem;
    position: relative;

    background-color: #000a0d;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.125em;

    transform: scale(0.1);
    opacity: 0;
    transition: all 0.3s;
    margin: 1.25rem;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.5rem;
    height: 2.5rem;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    transform: translate(50%, -50%);

    border: none;
    border-radius: 100px;

    transition: background-color 0.3s;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: #bebebe;
    }
  }

  &__container {
    padding: 2rem;
  }

  &__row {
    display: flex;
  }

  &__title {
    max-width: 15rem;

    font-family: Wremena;
    font-weight: 350;
    font-size: 2.5rem;
    line-height: 1.2;
    text-transform: uppercase;
    color: $white;
  }

  &__form {
    //max-width: 22.5rem;
    width: 100%;
  }

  &__form-row {
    height: 3rem;

    input {
      width: 100%;
      height: 100%;

      padding: 0 1.25rem;
      overflow: visible;
      outline: 0;
      margin: 0;

      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.2);
      font-family: Futura PT;
      color: rgba(255, 255, 255, 0.8);
      font-size: 1rem;
    }
  }

  &__map {
    max-width: 900px;
    width: 100%;
  }

  .form-footer {
    height: 1rem;
    color: rgba(255, 255, 255, 0.2);
    margin: 0 1.25rem;
  }

  &.active {
    visibility: visible;

    .popup__body {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.popup_make-order {
  .popup__body {
    height: auto;
  }

  .popup__row {
    flex-direction: column;
  }

  .popup__form {
    display: flex;
    flex-direction: column;
    //align-items: flex-start;
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
  }

  .popup__form-row {
    text-align: center;
  }
}

.popup-catalog {
  .popup__body {
    height: auto;
  }
  .popup__row {
    justify-content: space-between;
  }
  .popup__content-inner {
    max-width: 400px;
    width: 100%;
  }

  .popup__list-btn {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
  .popup__contact-info {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    width: 100%;

    .popup__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
  .popup__decoration {
    width: 100%;
  }
  .catalog__carousel-cell {
    width: 90%;
    height: 400px;
    padding: 0 2rem 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }
}

.popup_map {
  .popup__body {
    max-width: 900px;
    width: 100%;
    height: auto;
  }
  .popup__map {
    width: 1000px;
  }
}

.about {
  background-color: $white;
  color: $black;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 5rem 0;

  @media (max-width: 767px) {
    padding: 2rem 0;
  }

  &__row {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
  }

  &__title {
    margin: 5rem 0;
    text-align: center;
    font-size: 4rem;
    font-weight: 500;
  }

  &__first {
    display: flex;
    align-items: center;

    .about__text {
      padding-right: 3rem;
      @media (max-width: 767px) {
        padding-right: 0;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__image {
    max-width: 800px;
    width: 100%;
    height: 400px;

    @media (max-width: 767px) {
      height: 200px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  p {
    margin: 1.75rem 0;

    @media (max-width: 767px) {
      margin: 1.5rem 0;
      font-size: 1rem;
    }
  }
}

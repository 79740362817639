@keyframes pulse {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(#aaff3f, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.4em rgba(#aaff3f, 0);
  }

  100% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(#aaff3f, 0);
  }
}

@keyframes pulse-black {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(#fff, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.4em rgba(#aaa, 0);
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(#fff, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInLight {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes fadeRight {
  0% {
    transform: translate3d(-1em, 0em, 0em);
    opacity: 0;
  }

  100% {
    transform: translate3d(0em, 0em, 0em);
    opacity: 1;
  }
}

@keyframes fadeDownBounce {
  0% {
    transform: translate3d(0em, -1em, 0em) rotate(180deg);
    opacity: 0;
  }

  60% {
    transform: translate3d(0em, 0.2em, 0em) rotate(-10deg);
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 0em, 0em) rotate(0);
    opacity: 1;
  }
}

@keyframes fadeDown {
  0% {
    transform: translate3d(0em, -1em, 0em);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 0em, 0em);
    opacity: 1;
  }
}

@keyframes fadeRight {
  0% {
    transform: translate3d(-5em, 0, 0em);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 0em, 0em);
    opacity: 1;
  }
}

@keyframes fadeRightSpin {
  0% {
    transform: translate3d(-5em, 0, 0em) rotate(-45deg);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 0em, 0em) rotate(0deg);
    opacity: 1;
  }
}

@keyframes fadeLeftSpinOut {
  0% {
    transform: translate3d(0, 0, 0em) rotate(0);
    opacity: 1;
  }

  100% {
    transform: translate3d(-5em, 0em, 0em) rotate(-45deg);
    opacity: 0;
  }
}

@keyframes fadeRightUp {
  0% {
    transform: translate3d(-5em, 5em, 0em);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 0em, 0em);
    opacity: 1;
  }
}

@keyframes fadeRightUpOut {
  0% {
    transform: translate3d(0, 0, 0em);
    opacity: 1;
  }

  100% {
    transform: translate3d(5em, -5em, 0em);
    opacity: 0;
  }
}

@keyframes fadeLeft {
  0% {
    transform: translate3d(5em, 0, 0em);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 0em, 0em);
    opacity: 1;
  }
}

@keyframes fadeLeftOut {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(-5em, 0em, 0em);
    opacity: 0;
  }
}

@keyframes fadeRightOut {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(5em, 0em, 0em);
    opacity: 0;
  }
}

@keyframes fadeDownRight {
  0% {
    transform: translate3d(-0.3125em, -0.3125em, 0em);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 0em, 0em);
    opacity: 1;
  }
}

@keyframes fadeUp {
  0% {
    transform: translate3d(0em, 1em, 0em);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 0em, 0em);
    opacity: 1;
  }
}

@keyframes fadeBigUp {
  0% {
    transform: translate3d(0em, 10em, 0em);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 0em, 0em);
    opacity: 1;
  }
}

@keyframes fadeDownOut {
  0% {
    transform: translate3d(0em, 0em, 0em);
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 1em, 0em);
    opacity: 0;
  }
}

@keyframes fadeUpOut {
  0% {
    transform: translate3d(0em, 0em, 0em);
    opacity: 1;
  }

  100% {
    transform: translate3d(0em, 1em, 0em);
    opacity: 0;
  }
}

@keyframes flight-card {
  0% {
    filter: blur(0);
  }

  100% {
    filter: blur(7px);
    transform: translate(140%, 0) rotate(45deg);
  }
}

@keyframes flight-card-mobile {
  0% {
    filter: blur(0);
  }

  100% {
    filter: blur(7px);
    transform: translate(140%, -50%) rotate(45deg);
  }
}

@keyframes flash {
  0% {
    filter: blur(0);
  }

  100% {
    filter: blur(7px);
    transform: translate(140%, -50%) rotate(45deg);
  }
}

@keyframes rotateWords {
  0% {
    animation-timing-function: ease-out;
    width: 0px;
  }

  10% {
    width: 20%;
  }

  20% {
    width: 30%;
  }

  27% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

@keyframes card-hidden {
  0% {
    transform: rotate(-5deg) translate(3.5em, 1em);
    opacity: 1;
  }

  100% {
    transform: translate(100%, -100%) rotate(45deg);
    opacity: 0;
  }
}

@keyframes card-show {
  0% {
    transform: translate(100%, -100%) rotate(45deg);
    opacity: 0;
  }

  100% {
    transform: rotate(-5deg) translate(3.5em, 1em);
    opacity: 1;
  }
}

@keyframes card-hidden-mobile {
  0% {
    transform: rotate(-5deg) translate(1.875em, 0.5em);
    opacity: 1;
  }

  100% {
    transform: translate(100%, -100%) rotate(45deg);
    opacity: 0;
  }
}

@keyframes card-show-mobile {
  0% {
    transform: translate(100%, -100%) rotate(45deg);
    opacity: 0;
  }

  100% {
    transform: rotate(-5deg) translate(1.875em, 0.5em);
    opacity: 1;
  }
}

@keyframes arrow-move {
  0% {
    transform: translate3d(0, -0.2em, 0);
  }

  50% {
    transform: translate3d(0, 0.2em, 0);
  }

  100% {
    transform: translate3d(0, -0.2em, 0);
  }
}

@keyframes rotateLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.residential {
  &__row {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
  }
  &__list {
    margin-top: 2rem;

    &-params {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__item {
        font-size: 16px;

        & > span {
          font-weight: 500;
        }
      }
    }
  }
}

.card {
  margin-bottom: 3rem;
  position: relative;
  z-index: 0;

  @media (max-width: 767px) {
    margin-bottom: 1.25rem;
  }

  @media (min-width: 768px) {
    min-height: 25rem;

    &_complex {
      &:after,
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 2px;
      }

      &:before {
        background-color: #666;
        top: 0.3rem;
        right: -0.3125rem;
        z-index: -1;
      }

      &:after {
        background-color: #333;
        top: 0.6rem;
        right: -0.625rem;
        z-index: -2;
      }
    }

    &:not(.card_complex) {
      .card__inner {
        margin-top: 0.7em;
      }
    }
  }

  &__container {
    @media (min-width: 768px) {
      display: flex;
      min-height: 25rem;
    }
  }

  &__img {
    flex: 0 0 40rem;
    position: relative;

    @media (max-width: 767px) {
      height: 20rem;
      border-radius: 0.25rem 0.25rem 0 0;
      overflow: hidden;
    }

    img {
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: auto;
    justify-content: space-between;
    padding: 2rem 2rem 0.5rem 2rem;
    background-color: $white;
    color: $black;
    border-radius: 0 2px 2px 0;

    @media (max-width: 767px) {
      padding: 1.25rem;
      border-radius: 0 0 0.25rem 0.25rem;
    }

    > a {
      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }

  &__title {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.1;
    text-decoration: none;
    font-family: 'Wremena', sans-serif;
    color: $accent;
    padding-right: 1.267rem;

    @media (max-width: 767px) {
      padding: 0;

      &.small {
        font-size: 1.5rem;
      }
    }
  }

  &__inner {
    display: none;
    padding-right: 4.75em;
    margin-top: 1em;

    &.show {
      display: block;

      @media (max-width: 767px) {
        display: flex;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      padding: 0;
      margin-top: 0;
    }
  }

  &__description {
    font-size: 1.25em;
    line-height: 1;
    margin: 0 0 1.6em;

    &:empty {
      display: none;
    }

    @media (max-width: 767px) {
      font-size: 1em;
      line-height: 1.1;
      margin: 1em 0 0;
      order: 3;
    }
  }

  &__row-info {
    display: flex;
    align-items: flex-start;
    margin-top: 2.5em;

    @media (max-width: 767px) {
      margin-top: 1em;
      order: 1;

      &_col-mob {
        flex-direction: column;
      }
    }
  }

  &__price {
    font-family: 'Futura PT';
    &-label {
      font-size: 1.25rem;
      margin-bottom: 0.6rem;
      line-height: 1;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &-nums {
      font-size: 1.875rem;
      line-height: 1;
      font-weight: 500;

      @media (max-width: 767px) {
        font-size: 1rem;
      }
    }

    &-note {
      font-size: 0.55rem;
      opacity: 0.5;
    }
  }

  &__info {
    display: flex;
    margin-left: 1rem;
    font-size: 1.125rem;

    &_column {
      flex-direction: column;
      margin-top: 0.9rem;

      @media (max-width: 767px) {
        display: none;
      }
    }

    @media (max-width: 767px) {
      font-size: 1rem;
      line-height: 1;
      margin-left: 1.5rem;
    }

    &-item {
      &:not(:first-child) {
        margin-left: 2.5rem;
      }

      @media (max-width: 767px) {
        &_developer {
          display: none;
        }

        &:not(:first-child) {
          margin-left: 0;
        }
      }
    }

    &-label {
      opacity: 0.5;
      margin-bottom: 0.111rem;
      line-height: 1;

      @media (max-width: 767px) {
        display: none;
      }
    }

    &-text {
      line-height: 1;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;
    gap: 1rem;
  }
}

.partners-page {
  background-color: $white;
  color: $black;
  padding: 5rem 0;

  @media (max-width: 767px) {
    padding: 3rem 0;
  }

  &__row {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
  }

  &__title {
    margin: 5rem 0;
    text-align: center;
    font-size: 3rem;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 3rem;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 7rem;

    @media (max-width: 767px) {
      gap: 5rem;
    }
  }

  &__quote {
    display: flex;
    width: 800px;
    background-color: $white-hover;
    padding: 2.5rem;
    border-radius: 1rem;
    margin-top: 4rem;

    @media (max-width: 767px) {
      max-width: 800px;
      width: 100%;
      padding: 1.5rem;
    }

    @media (max-width: 569px) {
      flex-direction: column;
    }

    &-left {
      max-width: 800px;
      width: 100%;
    }

    &-right {
      max-width: 300px;
      width: 100%;
      position: relative;

      @media (max-width: 569px) {
        width: 200px;
        height: 150px;
      }
    }

    &-image {
      position: absolute;
      bottom: -2.5rem;
      right: -2.5rem;

      img {
        width: 300px;
      }

      @media (max-width: 767px) {
        bottom: -1.5rem;
        right: -1.5rem;

        img {
          width: 250px;
        }
      }

      @media (max-width: 569px) {
        bottom: -1.5rem;
        right: auto;
        left: -1.5rem;

        img {
          width: 100%;
        }
      }
    }

    &-text {
      font-size: 1.25rem;
      color: $black;
      font-style: italic;
      line-height: 1.5;

      @media (max-width: 767px) {
        font-size: 1rem;
        line-height: 1.5;
      }
    }

    &-author {
      margin-top: 1rem;
      font-size: 1.5rem;

      @media (max-width: 767px) {
        font-size: 1.25rem;
      }

      @media (max-width: 569px) {
        text-align: right;
        margin-top: 1.5rem;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: center;

    &-title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 2rem;
      font-size: 4rem;
      font-weight: 700;

      min-width: 450px;
      width: 100%;

      padding-right: 3rem;

      letter-spacing: 0.1rem;

      @media (max-width: 767px) {
        min-width: 0;
        font-size: 2.75rem;
        padding: 3px 0;
        gap: 1rem;
      }

      .border {
        width: 100%;
        height: 2px;
        background-color: $accent;
      }
    }

    &-text {
      font-size: 1.25rem;
      line-height: 1.75;

      p {
        margin: 1.75rem 0;
        @media (max-width: 767px) {
          margin: 1.5rem 0;
          font-size: 1rem;
        }
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
}

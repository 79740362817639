.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem;

  border: 1px solid transparent;
  font-family: 'Futura PT';
  cursor: pointer;

  text-decoration: none;
  text-align: center;
  letter-spacing: 0.1rem;
  border-radius: 0.25rem;

  font-size: 1em;
  color: $white;
  background-color: $accent;

  transition: 0.3s;

  &.active {
    background-color: $accent-hover;
  }

  &:hover {
    background-color: $accent-hover;
  }

  &_dark {
    background-color: $accent-dark;
    border: 1px solid rgba(rgb(255, 255, 255), 0.1);

    &.active {
      background-color: $accent-dark-hover;
    }

    &:hover {
      background-color: $accent-dark-hover;
    }
  }

  &_error {
    border: 1px solid $error;
  }

  &_trans {
    background: transparent;

    &.active {
      color: $accent-hover;
    }

    &:hover {
      color: $accent-hover;
    }
  }

  &_full {
    width: 100%;
  }

  &_rect {
    border-radius: 0;
  }
}

$black: #000a0d;
$black-hover: #02191a;

$white: #fff;
$white-hover: #f5f6fa;

$accent: #d29c36;
$accent-hover: #bf953f;

$error: #830f0f;

$accent-dark: #0c1b20;
$accent-dark-hover: #000a0d;

$gold-gradient: linear-gradient(90deg, #ae8625, #e1c03b, #d2ac47, #edc967);

$container-max-width: 1300px;
$container-middle-max-width: 91rem;

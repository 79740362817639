.favourite {
  background-color: $white;
  color: #000;
  font-family: Wremena;

  &__row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8em 0 4em 0;
    padding: 0 0.5em;
  }

  &__title {
    margin-top: 1em;
  }

  table {
    margin-top: 3em;
  }

  .btn-close {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;

    background-color: $white;
    border: none;
    border-radius: 100px;
    cursor: pointer;

    transition: background-color 0.3s;

    &:hover {
      background-color: #c5c5c5;
    }
  }

  th,
  td {
    padding: 1em;
    vertical-align: middle;
    text-align: center;
    font-size: 1.25em;
  }

  &__body-image {
    //display: block;
    padding: 0;
    margin-left: 1em;
    max-width: 200px;
    width: 200px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
    }
  }

  &__body-status {
    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      span {
        color: green;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__body-image {
      display: flex;
      padding: 0;
      min-width: 150px;
      max-width: 100%;
      width: 100px;

      img {
        height: 100%;
        max-width: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }

    &__head-select {
      display: none;
    }
  }
}

.filters {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
  transition: all 0.4s;
  flex-basis: 30%;

  max-width: 300px;
  width: 100%;
  //padding-bottom: .rem;

  button {
    border-radius: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f5f4f4;
    padding: 1em 2em;
    //border-radius: .5em;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Wremena;
    font-size: 1.25em;
    font-weight: 500;

    &.list {
      cursor: pointer;
    }
  }

  &__apply {
    margin-top: 1em;
  }

  &__inputs {
    margin-top: 0.5rem;
  }

  input[type='number'] {
    width: 5rem;
    height: 2rem;
    padding: 0 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  @media screen and (max-width: 953px) {
    position: absolute;
    top: 3rem;
    right: 0;

    max-height: 0;
    overflow: hidden;
    opacity: 0;

    z-index: 100;

    &.active {
      opacity: 1;
      max-height: 1500px;
      overflow: scroll;
    }
  }
}

.filters-range {
  &__inputs {
    display: flex;
    flex-direction: row;
  }

  &__label {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-right: 0.75em;
  }

  &__text {
    margin-right: 8px;
  }

  input[type='number'] {
    width: 7em;
    height: 3em;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}

.filters-list {
  &__title {
    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }

  &__inputs {
    display: none;
    flex-direction: column;
    gap: 1rem;
    font-size: 1rem;

    &.active {
      display: flex;
    }
  }

  input[type='checkbox'] {
    width: 40px;
    height: 40px;
  }
}

.filter_area {
  display: flex;

  @media screen and (max-width: 953px) {
    position: relative;
    width: 100%;
    height: 3rem;
    background-color: $black;
  }
}

.menu_list {
  color: white;

  span {
    font-size: 3rem;
  }

  @media screen and (max-width: 953px) {
    position: relative;
    width: 100%;
    height: 3rem;
    background-color: $black;

    span {
      display: block;
      width: 100%;
    }
  }
}

.popup-contacts {
  bottom: 1rem;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 100000;

  &__container {
    padding: 0 1rem;
    display: flex;
    justify-content: flex-end;
  }
  &__inner {
    flex-direction: column;
  }
  &__button {
    border-radius: 100rem;
    display: flex;
    justify-content: flex-end;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 5rem;
      height: 5rem;
      background-color: #fff;
      border-radius: 100%;
      box-shadow: 0 0 0 0 #000;
      animation: pulse-black 5s infinite;
    }

    &__inner {
      align-items: center;
      //backdrop-filter: blur(2px);
      background: linear-gradient(
        135deg,
        hsla(0, 0%, 100%, 0.2),
        hsla(0, 0%, 100%, 0)
      );
      border-radius: 100em;

      -webkit-box-shadow: 0 0 28px -5px rgba(0, 0, 0, 0.61);
      -moz-box-shadow: 0 0 28px -5px rgba(0, 0, 0, 0.61);
      box-shadow: 0 0 28px -5px rgba(0, 0, 0, 0.61);
      cursor: pointer;
      display: flex;
      height: 5rem;
      justify-content: center;
      pointer-events: visible;
      position: relative;
      transition: 0.25s;
      width: 5rem;
      z-index: 1;
    }

    &__icon {
      &-open {
        position: absolute;
        top: 22px;

        > svg {
          width: 37px;
          height: 37px;
          color: black;
          transform: rotate(180deg) scale(0);
          transition: all ease-in 0.2s;
        }
        &.show {
          > svg {
            transform: rotate(0) scale(1);
          }
        }
      }
      &-close {
        position: absolute;
        top: 32px;

        > svg {
          width: 15px;
          height: 16px;
          color: black;
          transform: rotate(180deg) scale(0);
          transition: all ease-in 0.2s;
        }
        &.show {
          > svg {
            transform: rotate(0) scale(1);
          }
        }
      }
    }
  }

  &__body {
    pointer-events: all;
    z-index: 100;
    background-color: white;
    position: relative;
    margin-bottom: 8px;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out 0.2s;
    transform: translate3d(0, -20%, 0);
    border-radius: 10px;
    -webkit-box-shadow: 0 0 28px -5px rgba(0, 0, 0, 0.61);
    -moz-box-shadow: 0 0 28px -5px rgba(0, 0, 0, 0.61);
    box-shadow: 0 0 28px -5px rgba(0, 0, 0, 0.61);

    &:before {
      position: absolute;
      bottom: -7.1px;
      right: 32px;
      left: auto;
      display: inline-block !important;
      border-right: 8px solid transparent;
      border-top: 8px solid;
      border-left: 8px solid transparent;
      content: '';
    }

    &-container {
      overflow: hidden;
    }

    &.show {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &_list {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0;

      &-item {
        border: 0 none;
        background-color: transparent;
        border-radius: 0;
        outline: none;
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 10rem 8px 15px;
        min-height: 54px;
        color: black;
        text-decoration: none;
        transition: all ease-out 0.3s;

        &:hover {
          background-color: #eeeeee;
        }

        > span {
          width: 48px;
          height: 48px;
          border-radius: 100%;
          position: relative;
          margin-right: 10px;

          > svg {
            width: 30px;
            height: 30px;
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

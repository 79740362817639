.product-head {
  margin-top: 140px;

  &__row {
    display: flex;
    flex-direction: column;
  }

  .carousel-cell {
    width: auto;
    height: 27.25rem;
    line-height: 0;
    transition: 0.3s;
    background: $black;
    overflow-y: hidden;

    object-fit: cover;
    object-position: center;

    &:not(:first-child) {
      margin-left: 1rem;
    }

    @media (max-width: 767px) {
      height: 25rem;
      border-radius: 0.5rem;
    }
  }

  &__info {
    margin-top: 8px;
    padding: 8px 12px;
  }
  &__article {
    font-size: 16px;
  }
  &__title {
    margin: 16px 0 8px 0;
    font-family: Wremena;
    font-size: 32px;
  }
  &__price {
    margin: 8px 0;
    font-size: 28px;
  }
  &__price-hundred {
    font-size: 20px;
  }
  &__actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
  }
  @media screen and (max-width: 768px) {
    &__row {
      flex-direction: column;
    }

    .carousel-cell {
      width: 100%;
      padding: 0.5rem;
      border-radius: 1rem;
      object-fit: cover;
    }
  }
}

.product-detail {
  margin-top: 36px;
  background-color: $white;

  &__row {
    display: flex;
  }

  &__info {
    display: flex;
    justify-content: center;
    max-width: 500px;
    width: 100%;
    padding: 28px 12px;
    background-color: #f7f7f7;
    color: $black;
    font-size: 16px;
  }
  &__info-list {
    display: flex;
    flex-direction: column;
    line-height: 2;
    //padding-left: 150px;
    gap: 12px;

    li {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    span {
      font-weight: 500;
    }
  }
  &__description {
    max-width: 768px;
    width: 100%;
    padding: 24px;
    margin-left: 40px;
    color: $black;
    font-size: 16px;
    line-height: 2;
    tab-size: 4px;
  }

  @media screen and (max-width: 960px) {
    &__info {
      max-width: 300px;
    }
  }
  @media screen and (max-width: 768px) {
    &__row {
      flex-direction: column;
    }

    &__info {
      max-width: 100%;
      width: 100%;
      padding: 24px 0;
    }

    &__description {
      max-width: 100%;
      //width: 100%;
      margin: 0;
      padding: 1rem;
      p {
        padding: 12px 24px;
      }
    }
  }
}

.scroll-container {
  [data-scroll-section] {
    background-color: $black;
  }
}

.capitalize {
  text-transform: capitalize !important;
}

.relative {
  position: relative !important;
}

.fast_background_animate {
  will-change: background-color;
  background-color: #000 !important;
  animation: fast_background_animate 1s infinite ease-in-out;
  overflow: hidden;
}

.fb_dynamic_url.fast_background_animate,
.fb_loaded.fast_background_animate // , .fast_background_animate:not(img[src=""])
{
  animation: none !important;
  background-color: transparent !important;
}

@keyframes fast_background_animate {
  0% {
    filter: opacity(15%);
  }

  50% {
    filter: opacity(30%);
  }

  100% {
    filter: opacity(15%);
  }
}

.site-wrapp {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  &:not(.site-wrapp_index) {
    .header__photo-desc {
      display: none !important;
    }
  }

  &_history {
    @media (max-width: 767px) {
      .btn-up {
        display: none;
      }

      .scroll-container [data-scroll-section] {
        &.header {
          background-color: transparent;
        }
      }
    }
  }

  &_projects {
    .header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    [data-scroll-section] {
      background-color: #000;
    }
  }

  &_awards {
    .header {
      transition: 1s;

      &.hidden {
        transform: translateY(-200%);
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &.country {
    @media (min-width: 768px) {
      .filter-main__group_price {
        flex: 1;
      }
    }
  }

  &.retail,
  &.country {
    .filter-main {
      @media (min-width: 768px) {
        &__title {
          width: 15.5em;
          margin-right: 0.767em;
        }
      }
    }
  }

  &_article {
    overflow: hidden;
  }
}

.body-inner {
  position: relative;
}

.section__subtitle {
  font-size: 1.875em;
  line-height: 1.1;
  opacity: 0.8;

  @media (max-width: 767px) {
    font-size: 1em;
  }
}

.color-accent {
  color: $accent;
}

.is-mobile {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.is-desktop {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.fix-height-mobile {
  min-height: unset;
  height: 100%;
  overflow: hidden;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.office-switchable {
  &:not(.active) {
    display: none;
  }
}

sup {
  top: -0.37em;
  font-size: 60%;
}

.handwritten-animate {
  opacity: 0;

  &.show {
    opacity: 1;
  }
}

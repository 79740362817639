.cookie-block {
  display: flex;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 98;
  display: none;

  @media (max-width: 767px) {
    position: static;
    margin-bottom: -1em;
  }

  &.show {
    display: flex;
  }

  &.hidden {
    display: none;
  }

  &__body {
    width: 100%;
    border-radius: 10px 10px 0 0;
    background: rgba(240, 234, 227, 0.01);
    backdrop-filter: blur(6px);
    padding: 1em 0;

    @media (max-width: 767px) {
      border-radius: 0;
    }
  }

  &__inner {
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
      justify-content: center;
    }

    @media (max-width: 767px) {
      align-items: flex-end;
      gap: 3.125em;
    }
  }

  &__text {
    font-size: 0.875em;
    line-height: 1.1;

    @media (max-width: 767px) {
      font-size: 0.625em;
    }

    a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      border-bottom: 0.0625em solid $white;
    }
  }

  &__btn {
    font-size: 0.875em;
    color: $black;
    background-color: $white;
    font-family: 'Futura PT', sans-serif;
    border: 0;
    border-radius: 4px;
    padding: 0.286em 0.571em;
    cursor: pointer;

    @media (min-width: 768px) {
      margin-left: 3.625em;
    }
  }
}

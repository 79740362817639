@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/FuturaPT/FuturaPT-Heavy.eot');
  src:
    local('Futura PT Heavy'),
    local('FuturaPT-Heavy'),
    url('../fonts/FuturaPT/FuturaPT-Heavy.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-Heavy.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/FuturaPT/FuturaPT-Medium.eot');
  src:
    local('Futura PT Medium'),
    local('FuturaPT-Medium'),
    url('../fonts/FuturaPT/FuturaPT-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-Medium.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/FuturaPT/FuturaPT-MediumObl.eot');
  src:
    local('Futura PT Medium Oblique'),
    local('FuturaPT-MediumObl'),
    url('../fonts/FuturaPT/FuturaPT-MediumObl.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-MediumObl.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-MediumObl.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/FuturaPT/FuturaPT-LightObl.eot');
  src:
    local('Futura PT Light Oblique'),
    local('FuturaPT-LightObl'),
    url('../fonts/FuturaPT/FuturaPT-LightObl.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-LightObl.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-LightObl.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Book';
  src: url('../fonts/FuturaPT/FuturaPT-Book.eot');
  src:
    local('Futura PT Book'),
    local('FuturaPT-Book'),
    url('../fonts/FuturaPT/FuturaPT-Book.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-Book.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Book';
  src: url('../fonts/FuturaPT/FuturaPT-BookObl.eot');
  src:
    local('Futura PT Book Oblique'),
    local('FuturaPT-BookObl'),
    url('../fonts/FuturaPT/FuturaPT-BookObl.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-BookObl.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-BookObl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/FuturaPT/FuturaPT-Light.eot');
  src:
    local('Futura PT Light'),
    local('FuturaPT-Light'),
    url('../fonts/FuturaPT/FuturaPT-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-Light.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/FuturaPT/FuturaPT-BoldObl.eot');
  src:
    local('Futura PT Bold Oblique'),
    local('FuturaPT-BoldObl'),
    url('../fonts/FuturaPT/FuturaPT-BoldObl.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-BoldObl.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-BoldObl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Demi';
  src: url('../fonts/FuturaPT/FuturaPT-DemiObl.eot');
  src:
    local('Futura PT Demi Oblique'),
    local('FuturaPT-DemiObl'),
    url('../fonts/FuturaPT/FuturaPT-DemiObl.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-DemiObl.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-DemiObl.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Demi';
  src: url('../fonts/FuturaPT/FuturaPT-Demi.eot');
  src:
    local('Futura PT Demi'),
    local('FuturaPT-Demi'),
    url('../fonts/FuturaPT/FuturaPT-Demi.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-Demi.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/FuturaPT/FuturaPT-HeavyObl.eot');
  src:
    local('Futura PT Heavy Oblique'),
    local('FuturaPT-HeavyObl'),
    url('../fonts/FuturaPT/FuturaPT-HeavyObl.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-HeavyObl.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-HeavyObl.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/FuturaPT/FuturaPT-Bold.eot');
  src:
    local('Futura PT Bold'),
    local('FuturaPT-Bold'),
    url('../fonts/FuturaPT/FuturaPT-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/FuturaPT/FuturaPT-Bold.woff') format('woff'),
    url('../fonts/FuturaPT/FuturaPT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wremena';
  src:
    url('../fonts/Wremena/Wremena.ttf.woff') format('woff'),
    url('../fonts/Wremena/Wremena.ttf.svg#Wremena') format('svg'),
    url('../fonts/Wremena/Wremena.ttf.eot'),
    url('../fonts/Wremena/Wremena.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
